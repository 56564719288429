import { colors, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import NotiSnackbar from './components/NotiSnackbar';
import TaskInput from './components/TaskInput';
import TaskTable from './components/TaskTable';
import ToDoAppBar from './components/ToDoAppBar';
import useNetwork from './hooks/useNetwork';
import useTodoApi from './hooks/useToDoApi';
import { AppMsg } from './Types';
import Alert from '@material-ui/lab/Alert';
import useContract from './hooks/useContract';

const useStyles = makeStyles((theme) => ({
  paper: {
    flexGrow: 1,
    maxWidth: 700,
    margin: "50px auto",
    padding: theme.spacing(10),
    textAlign: 'center',
  },
  alert: {
    marginLeft: "auto",
    width: 'fit-content',
  },
  textfield: {
    width: '100%',
    marginRight: 10,
  },
}))

function App() {
  const classes = useStyles()
  const [appMsg, setAppMsg] = useState<AppMsg>(["info", ""])
  const [contractAddress, setContractAddress] = useContract();
  const [{ wallet, network, account, theWeb3, isConnected }, connectNetwork, disconnectNetwork] = useNetwork({ setAppMsg })
  const [tasks, { createTask, toggleDone, deleteTask }] = useTodoApi({ theWeb3, contractAddress, account, setAppMsg })

  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: colors.yellow[600],
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToDoAppBar {...{ wallet, network, account, isConnected, connectNetwork, disconnectNetwork, setAppMsg }} />
      <Alert severity="info" className={classes.alert}>Try connect Kovan test network by MetaMask</Alert>

      <Paper elevation={5} className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="ToDo Contract Address"
              placeholder="0x123..."
              type="text"
              className={classes.textfield}
              onChange={setContractAddress}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={5} className={classes.paper}>
        <Grid container spacing={5}>
          <div style={{ flexGrow: 1 }}></div>
          <Grid item xs={12}>
            <TaskInput createTask={createTask} />
          </Grid>
          <Grid item xs={12}>
            <TaskTable
              tasks={tasks}
              toggleDone={toggleDone}
              deleteTask={deleteTask} />
          </Grid>
        </Grid>
      </Paper>
      <SnackbarProvider maxSnack={3}>
        <NotiSnackbar appMsg={appMsg} />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
