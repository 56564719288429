import { ContractAddress } from './../Types';

import { useCallback, useState } from "react"

function useContract(): ContractAddress {
    const [contractAddress, setContractAddress] = useState<string>("");

    const update = useCallback((props) => {
        setContractAddress(props.target.value);
    }, [])

    return [contractAddress, update]
}

export default useContract;
